<template>
  <v-menu v-if="$global.user" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        text
        v-on="on"
      >
        <v-icon>person</v-icon>
        {{ $global.user.first_name }} {{ $global.user.last_name }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="$global.user">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-list-item-title v-on="on" @click="copy">{{$global.user.id}}</v-list-item-title>
          </template>
          <span v-if="copied">Copied!</span>
          <span v-else>Click to copy</span>
        </v-tooltip>
      </v-list-item>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        router
        :to="item.to">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import clipboard from '@/services/clipboard';

export default {
  name: 'UserMenu',
  data() {
    return {
      items: [
        {
          title: 'Change password',
          to: '/change_password',
        },
        {
          title: 'Log out',
          to: '/logout',
        },
      ],
      copied: false,
    };
  },
  methods: {
    copy(e) {
      e.preventDefault();
      clipboard(this.$global.user.id);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
};
</script>
